import { Dispatch } from "react";
import { fetchUserStart, fetchUserFailure } from "../state/user/userSlice";
import { addMessage } from "../state/message/messageSlice";
import { session } from "websdk-core";

export const fetchUserData = async (
  dispatch: Dispatch<any>
): Promise<string | null> => {
  try {
    dispatch(fetchUserStart());
    return new Promise((resolve, reject) => {
      session.retrieveUser().subscribe({
        next: async (data: any) => {
          try {
            const userData = typeof data === "string" ? JSON.parse(data) : data;

            if (!userData || typeof userData !== "object") {
              throw new Error("Invalid user data structure.");
            }
            const agentEmail = userData.USER_ALIAS;
            const agentSign = userData.AGENT_SIGN;
            const agentInitials = userData.AGENT_INITIALS;
            let baseUrl: string = "";

            if (agentEmail != null) {
              baseUrl = process.env.REACT_APP_EMAIL_URL || "";
              if (!baseUrl) {
                throw new Error(
                  `Email URL is not defined in environment variables. Got: ${baseUrl}`
                );
              }
              const fullUrl = `${baseUrl}${agentEmail}`;

              const resData = await fetch(fullUrl);

              if (resData.ok) {
                const responseData = await resData.json();
                const logonName = responseData[0]?.logonName || null;
                resolve(logonName);
              }
            }
            if (agentSign != null && agentInitials != null) {
              baseUrl = process.env.REACT_APP_JUMPIN_ID_URL || "";

              if (!baseUrl) {
                throw new Error(
                  `Amadeus JumpIn URL is not defined in environment variables. Got: ${baseUrl}`
                );
              }
              const fullUrl = `${baseUrl}${agentSign}${agentInitials}`;

              const response = await fetch(fullUrl);
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }

              const responseData = await response.json();
              const logonName = responseData[0]?.logonName || null;

              resolve(logonName);
            } else {
              throw new Error(
                `Invalid data from Fetch user in Amadeus: agentEmail:${agentEmail} agentSign:${agentSign} agentInitials:${agentInitials}}`
              );
            }
          } catch (error) {
            handleError(error, dispatch);
            reject(null);
          }
        },
        error: (error: any) => {
          handleError(error, dispatch);
          reject(null);
        },
      });
    });
  } catch (error) {
    handleError(error, dispatch);
    return null;
  }
};

const handleError = (error: any, dispatch: Dispatch<any>) => {
  const errorMessage =
    error instanceof Error ? error.message : "An unknown error occurred";
  console.error(errorMessage);
  dispatch(fetchUserFailure(errorMessage));
  dispatch(addMessage(errorMessage));
};
